<template>
  <div class="map-wrapper">
    <van-nav-bar title="地图" left-arrow @click-left="onClickLeft" />
    <iframe class="iframe" :src="url" frameborder="0"></iframe>
    <footer_ />
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
export default {
    components: {
        footer_,
    },
  data() {
    return {
      url: '',
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  created() {
    this.url = `https://uri.amap.com/marker?position=${this.$route.query.location}`
  },
};
</script>
<style scoped>
.map-wrapper{
    height: 100vh;
}
iframe{
    height: calc(100vh - 92px); /* Adjust the height to occupy the remaining space, assuming 56px is the height of the nav bar */
    width: 100%;
}
.van-nav-bar {
    background: #3d92fb !important;
}
.van-nav-bar__title{
    color: #fff !important;
    background: #3d92fb;
}

</style>

import { TUIComponents, TUIChatKit } from "../TUIKit";
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { TUILogin } from "@tencentcloud/tui-core";
import {TUIChatService} from "@tencentcloud/chat-uikit-engine";
import url from '../module/api/base'
const mixin = {
    data() {
        return {
            pageSizes: [10, 20, 30, 40], //可选的分页数量
            start: 0, //开始数据
            pageSize: 10, //每页数量
            total: 0, //分页总数量
            pharmacy_id:'',
            activeName:'all', //tab
            input:'',//搜索
            imgUrl:[
                require('../assets/image/nodoctor.png'),
                require('../assets/image/noreport.png'),
                require('../assets/image/nopatient.png'),
                require('../assets/image/nodepartment.png'),
            ],
            drawer:false, //控制弹窗
            title:'创建'
        }
    },
    created() {
        this.pharmacy_id = sessionStorage.getItem('pharmacy_id');
    },

    methods: {
        //刷新
        refresh(){
            this.loadList();
        },
        //搜索
        search() {
            this.start = 0;
            this.loadList();
        },
        //分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.loadList();
        },
        //分页
        handleCurrentChange(val) {
            this.start = val * 10 - 10
            this.loadList();
        },
        //tab 切换
        handleClick(){
            this.start = 0
            this.total = 0
            this.loadList();
        },
        //删除
        Delete(search_db,query_key,query_value,update){
            let params = {
                search_db:search_db,
                query_type:'first'
            }
            let data = {
                query:{
                    [query_key]:query_value
                },
                update:update
            }
            this.$api.article.update(params,data).then(res=>{
                if(res){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.loadList()
                }
              
              })
        },
        onClickLeft(){
            this.$router.back(-1)
        },
        //关闭弹窗
        handleClose(){
            this.drawer = false 
        },
        img_url(table_name,name){
            console.log(url)
            // https://api.net-sun.com/img?static_path=Pres&file_name=aly-d06bf72932e064453ea18e69699b37bf.png&token=0bb8fd76-cc57-4311-b2cb-fadbe8eb4b7f&userrole_id=426a9bce-58bc-4b88-90e9-4f28287aee08
            // https://api.net-sun.com/
            console.log(url.baseUrl)
            let token = localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')).token : ''
            return `${url.baseUrl}img?static_path=${table_name}&file_name=${name}&token=${token}&userrole_id=${localStorage.getItem('userrole_id')}`
        },
        //请求的二次处理
        apireturn(search_db,query_id,query_value,query_type='first'){
            let params = {
                search_db:search_db,
                query_type:query_type,
            }
            let data = {
                query:[
                    {
                        fild:{[query_id]:query_value},
                        type: '=',
                        condition: '&&',
                    },
                    
                ]
            }
            return this.$api.article.query2(params,data)
        },
        //修改状态
        putstate(search_db,query_key,query_value,update){
            let params = {
                search_db:search_db,
                query_type:'first'
            }
            let data = {
                query:{
                    [query_key]:query_value
                },
                update:update
            }
            this.$api.article.update(params,data).then(res=>{
                if(res){
                    this.drawer = false
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.loadList()
                }
              
              })
        },
        IMSIG(key, id) {
            return new Promise((resolve, reject) => {
                if (!localStorage.getItem('user')) {
                    reject('User not logged in');
                    return;
                }
        
                if (sessionStorage.getItem(`IMSIG${key}`)) {
                    let IMSIG = JSON.parse(sessionStorage.getItem(`IMSIG${key}`));
                    if (Date.now() - IMSIG.Imcreated_at < 900000) {
                        this.iMlogin(key)
                            .then(() => {
                                resolve(); // 登录成功后 resolve
                            })
                            .catch((error) => {
                                reject(error); // 登录失败则 reject
                            });
                        return;
                    }
                }
        
                // 如果没有有效的 IMSIG 数据或者需要重新获取
                this.$api.article.InternetHospitalGet({search_db: 'IMSIG'}, {id})
                    .then((res) => {
                        if (res && res.data.result) {
                            res.data.result['Imcreated_at'] = Date.now();
                            sessionStorage.setItem(`IMSIG${key}`, JSON.stringify(res.data.result));
                            this.iMlogin(key)
                                .then(() => {
                                    resolve(); // 登录成功后 resolve
                                })
                                .catch((error) => {
                                    reject(error); // 登录失败则 reject
                                });
                        } else {
                            reject('Failed to fetch IMSIG data');
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        
        // 修改 iMlogin 函数，使其返回一个 Promise 对象
        iMlogin(key) {
            return new Promise((resolve, reject) => {
                let IMSIG = JSON.parse(sessionStorage.getItem(`IMSIG${key}`));
                if (!IMSIG) {
                    reject('IMSIG data not found');
                    return;
                }
        
                let loginInfo = {
                    SDKAppID: IMSIG.sdkappid,
                    userID: IMSIG.imid,
                    userSig: IMSIG.sign,
                    useUploadPlugin: true,
                    useProfanityFilterPlugin: true
                };
        
                TUILogin.login(loginInfo)
                    .then((res) => {
                        resolve(res); // 登录成功后 resolve
                    })
                    .catch((error) => {
                        console.error('Login failed:', error);
                        resolve(); // 登录失败也 resolve，继续往下执行后续代码
                    });
            });
        },
        async ChatMessage(id,doctorId,pres_body,info,type){
            let cloudCustomData = {
                orderId:info.ih_info !== 'None' ? JSON.parse(info.ih_info).id : 'None'
            }
            this.apireturn('Doctor','doctor_id',doctorId).then(res=>{
                let Doctor = res.data.result
                let texts = JSON.parse(pres_body.text)
                texts[0] = `${Doctor.name}${texts[0]}`
                let otc  = pres_body.Drugs.every(element =>{
                    return element.PublicDrugs[0].otc == '1'
                })
                // if(otc){
                //     texts.splice(4,0, '我的历史用药均为otc药品');
                // }
                texts.forEach(element => {
                    this.sendTextMessage(id,doctorId,element,JSON.stringify(cloudCustomData),type)
                });
            })
        },
        async sendTextMessage(id,doctorId,text,cloudCustomData,type){
                const options = {
                    to:id,
                    conversationType: type,
                    cloudCustomData:cloudCustomData,
                    payload: {
                        text: text,
                    },
                } 
                TUIChatService.sendTextMessage(options);
        },
        //药店 药品id查询替换
        return_drugs_id(){
            return localStorage.getItem('drugs_id') == 'None' ? sessionStorage.getItem('pharmacy_id') :localStorage.getItem('drugs_id')
        }
 
    }
}
export default mixin;
list<template>
  <div class="bbb">
    <van-nav-bar
      :title="state == 'Drugs' ? '选择历史用药' : '当前用药'"
      left-arrow
      @click-left="onClickLeft"
    />
    <div  id="Interrogation" class="time_slot yaopinxuanzhe">
      <div class="container">
        <div class="Drugs_top">
          <el-input
            placeholder="药品名、首字母"
            @input="debounce(50)"
            v-model="search_value"
          >
            <template slot="append">
              <el-button icon="el-icon-full-screen" @click="triggerFileInput"
                >扫码</el-button
              >
            </template>
          </el-input>
          <div class="margin_top">
            <van-tag class="margin_right" type="success">名字：地平片</van-tag>
            <van-tag type="info">名字拼音首字母：DPX</van-tag>
          </div>
          <van-dropdown-menu
            class="margin_top"
            v-if="search_value !== ''"
            active-color="#409EFF"
          >
            <van-dropdown-item
              title="按规格筛选"
              @change="changeSpecs('specsValue')"
              v-model="specsValue"
              :options="specss"
            />
            <van-dropdown-item
              title="按厂商筛选"
              @change="changeSpecs('manufacturingEnterpriseValue')"
              v-model="manufacturingEnterpriseValue"
              :options="manufacturingEnterprise"
            />
          </van-dropdown-menu>
          <div v-if="headershow">
            {{ LastUsedDrugs.length == 0 ? '' : '可能的药品：' }}
            <el-tag
              class="margin_top"
              v-for="tag in LastUsedDrugs"
              :key="tag.id"
              @click="DrugsCli(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
          <div>
            <barCode v-if="barCode_show"></barCode>
          </div>
          <div
            class="margin_top"
            v-if="
              drugSelected.length == 0 && tableData.length !== 0 && headershow
            "
          >
            <el-alert
              title="请从下表中选择药品"
              type="warning"
              center
              :closable="false"
              show-icon
            >
            </el-alert>
          </div>
        </div>
        <div class="Drugs_middle">
          <div
            class="margin_top"
            style="margin-bottom: 100px"
            v-if="tableData.length !== 0"
          >
            <ul class="infinite-list" v-infinite-scroll="load">
              <div
                v-for="(item, index) of new_tableData"
                class="bpd"
                :class="index !== 0 ? 'margin_top' : ''"
              >
                <div style="display: flex">
                  <div style="width: 100px" @click="drugs_details(item.id)">
                    <van-image
                      v-if="hasParameters(item.imgurl)"
                      width="100"
                      height="100"
                      :src="item.imgurl"
                      radius="8px"
                    />
                    <img
                      v-else
                      style="width: 90px; height: 80px; border-radius: 8px;"
                      src="../assets/image/Drugs.webp"
                      alt=""
                    />
                  </div>
                  <div style="width: 0; flex-grow: 1; margin-left: 10px;" class="item">
                    <div style="font-size: 16px; padding: 1px 5px">
                      <span style="border-bottom: 2px solid #f56c6c; font-weight: bold;"
                        >{{ item.PublicDrugs[0].name }}
                      </span>
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px; font-weight: bold;">
                      规格：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].specs
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px; font-weight: bold;">
                      生产企业：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].manufacturingEnterprise
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px; font-weight: bold;">
                      国药准字：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].approvalNumber
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px; font-weight: bold;">
                      医保编码：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].drugcode
                          : ''
                      }}
                    </div>
                    <div class="price_number">
                      <span class="margin-left4 price">
                        <span v-if="state == 'Drugs'"> </span>
                      </span>
                      <div @click.stop class="btn_number">
                        <el-input-number
                          v-model="item.number"
                          :min="0"
                          :max="item.maxNumber * 1"
                          :step="1"
                          @change="number_change(item, index)"
                          style="width: 150px"
                        ></el-input-number>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <van-action-sheet v-model="show" title="已选择药品">
              <div v-for="(item, index) of drugSelected" class="bpd margin_top">
                <div style="display: flex" @click="drugs_details(item.id)">
                  <div style="width: 100px">
                    <van-image
                      v-if="hasParameters(item.imgurl)"
                      width="100"
                      height="100"
                      :src="item.imgurl"
                    />
                    <img
                      v-else
                      style="width: 90px; height: 80px"
                      src="../assets/image/Drugs.webp"
                      alt=""
                    />
                  </div>
                  <div style="width: 0; flex-grow: 1">
                    <div style="font-size: 16px; padding: 1px 5px">
                      {{ item.name }}
                    </div>
                    <div style="font-size: 16px; padding: 1px 5px">
                      规格：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].specs
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px">
                      生产企业：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].manufacturingEnterprise
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px">
                      国药准字：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].approvalNumber
                          : ''
                      }}
                    </div>
                    <div style="font-size: 12px; padding: 1px 5px">
                      医保编码：{{
                        item.PublicDrugs.length !== 0
                          ? item.PublicDrugs[0].drugcode
                          : ''
                      }}
                    </div>
                    <div class="price_number">
                      <span
                        v-if="item.price !== '0'"
                        class="margin-left4 price"
                      >
                      </span>
                      <div @click.stop style="padding: 0 10px">
                        <van-stepper
                          input-width="20px"
                          button-size="20px"
                          v-model="item.number"
                          min="0"
                          :max="item.maxNumber"
                        />
                        <van-button
                          type="danger"
                          @click="drugsdefault(item, index)"
                          size="mini"
                          >删除</van-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-action-sheet>
          </div>
          <div v-else>
            <van-nodata :figure="imgurl" title="暂无药品信息" />
          </div>
        </div>
        <div class="Drugs_bottom">
          <div class="van-submit-bar__wrapper">
            <div
              class="van-submit-bar van-submit-bar--safe-area-inset-bottom van-hairline--top"
              style="padding: 0 5px"
            >
              <div v-for="tag in drugSelected" style="display: inline-block">
                <el-tag v-if="tag.number > 0" :key="tag.id">
                  {{ tag.name }} × <span class="label">{{ tag.number }}</span>
                </el-tag>
              </div>

              <div class="van-submit-bar__bar">
                <div class="van-submit-bar__text"></div>
                <div>
                  <span
                    v-if="state == 'Drugs'"
                    class="van-submit-bar__wrapper_text"
                    >具体价格以实际结算为准</span
                  >
                  <van-button @click="onClickLeft" type="warning" block
                    >选择完毕</van-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-button
        v-if="state == 'Drugs'"
        class="ShoppingCart"
        @click="drugsShow"
        icon="el-icon-goods"
        circle
      ></el-button>
    </div>
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
import base from './api/base.js';
import { Loading } from 'element-ui';
import mixin from '@/utils/mixin';
import barCode from './components/barCode.vue';
import sha1 from 'js-sha1';
import wx from '../assets/js/jweixin-1.6.0.js';
export default {
  mixins: [mixin],
  components: {
    footer_,
    barCode,
  },
  data() {
    return {
      value: 0,
      specss: [],
      manufacturingEnterprise: [],
      manufacturingEnterpriseValue: '',
      specsValue: '',
      tableData: [],
      search_value: '',
      show: false,
      headershow: true,
      disease: [],
      barCode_show: false,
      state: '',
      drugsList: [],
      number: 0,
      imgurl: require('../assets/image/nodepartment.png'),
      LastUsedDrugs: [],
      drugSelected: [],
      historyTableData: [],
      count: 0,
      new_tableData: [],
      limitationSelectedDrugId: [
        {
          id:'ZA09BAF0284020404080',
          name:'复方阿胶浆-48支',
        }
      ]
    };
  },
  methods: {
    load() {
    if(this.specsValue != '' || this.manufacturingEnterpriseValue != ''){
      return
    }
      this.count += 5
      if(this.count >= this.tableData.length){
        this.count = this.tableData.length
      }
      this.new_tableData = this.tableData.slice(0,this.count)
    },
    triggerFileInput() {
      // this.barCode_show = true
      let that = this;
      that.$api.article.getticket().then((res) => {
        let fullUrl = window.location.href;
        let url = fullUrl.split('#')[0];
        let timestamp = new Date().getTime();
        let sortStr = that.objKeySort({
          jsapi_ticket: res.data.result.ticket,
          noncestr: `${timestamp}`,
          timestamp: Math.ceil(timestamp / 1000),
          url,
        });
        wx.config({
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx674869ee215ee6e7', // 必填，公众号的唯一标识
          timestamp: Math.ceil(timestamp / 1000), // 必填，生成签名的时间戳
          nonceStr: `${timestamp}`, // 必填，生成签名的随机串
          signature: sha1(sortStr), // 必填，签名
          jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表
        });
        wx.ready(function (res) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              if (res.resultStr.indexOf('EAN_13') !== -1) {
                let str = res.resultStr.split(',');
                that.search_value = str[1];
                that.loadList();
              } else {
                if (that.isNumeric(res.resultStr)) {
                  that.search_value = res.resultStr;
                  that.loadList();
                } else {
                  that.$message.error('请扫描正确的药品码');
                }
              }
            },
          });
        });
      });
    },

    isNumeric(str) {
      return /^\d+$/.test(str);
    },
    objKeySort(arys) {
      //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
      var newkey = Object.keys(arys).sort();
      var newObj = ''; //创建一个新的对象，用于存放排好序的键值对
      for (var i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&';
      }
      return newObj.substring(0, newObj.length - 1);
    },
    barCode_show_fun() {
      this.barCode_show = false;
    },
    maxNumber(item, number, index) {
      if (number > item.maxNumber) {
        this.$message.error('当前药品数量超过最大值');
        this.tableData[index].number = item.maxNumber;
      }
      return item.maxNumber + 1;
    },
    removeZeroValues(array) {
      let arr = JSON.parse(JSON.stringify(array))
      const newArray = arr.filter((value) => value.number > 0);
      return newArray;
    },
    tableDataHeight() {
      let height = '70vh';
      if (this.search_value !== '' && this.search_value.length > 3) {
        height = '52vh';
      }
      return height;
    },
    number_change(item, index) {
      if (item.new_yx_id == 'None') {
        this.$message.error('当前药品不可选用，请联系管理员添加');
        this.$nextTick(() => {
          item.number = 0;
        });
        return;
      }
      // 检查选中的药品是否与限制药品冲突
      if (this.drugSelected.length > 0 && (this.limitationSelectedDrugId.some(drug => drug.id === item.drugcode) || 
          this.drugSelected.some(drug => this.limitationSelectedDrugId.some(limit => limit.id === drug.drugcode))) && item.number !== 0) {
          this.$message.error(`${this.limitationSelectedDrugId.map(drug => drug.name).join(', ')}该药品不可与其他药品同选`);
          this.$nextTick(() => {
            item.number = 0;
          });
        return;
      }
      let drugSelected = this.drugSelected;
      let drugSelectedIndex = this.drugSelected.findIndex((element) => {
        {
          return element.id == item.id;
        }
      });
      if (drugSelectedIndex !== -1) {
        this.drugSelected[drugSelectedIndex].number = item.number;
      } else {
        if (item.PublicDrugs[0].disable) {
          if (
            item.PublicDrugs[0].disable.length > 5 ||
            item.disable.length > 5
          ) {
            this.$message.error(
              item.PublicDrugs[0].disable.length > 5
                ? item.PublicDrugs[0].disable
                : item.disable
            );
            this.$nextTick(() => {
              item.number = 0;
            });
            return;
          }
        }
        if (drugSelected.length >= 10) {
          this.$message.error('最多可选择10种药品');
          this.$nextTick(() => {
            item.number = 0;
          });
          return;
        }
        let approvalNumberFlse = this.drugSelected.filter((item) => {
          return (
            item.PublicDrugs[0].approvalNumber.slice(0, 5) == '国药准字Z' ||
            item.PublicDrugs[0].approvalNumber.slice(0, 1) == 'Z'
          );
        });
        if (
          approvalNumberFlse.length >= 2 &&
          (item.PublicDrugs[0].approvalNumber.slice(0, 5) == '国药准字Z' ||
            item.PublicDrugs[0].approvalNumber.slice(0, 1) == 'Z')
        ) {
          this.$message.error('最多可选择两种中成药');
          this.$nextTick(() => {
            item.number = 0;
          });
          return;
        }
        drugSelected.push(item);
      }
      this.drugSelected = this.removeZeroValues(this.drugSelected);
      sessionStorage.setItem('drugSelected', JSON.stringify(this.drugSelected));
    },
    drugsdefault(item, index) {
      this.drugSelected.splice(index, 1);
      this.drugSelected.forEach((element) => {
        if (element.id == item.id) {
          element.number = '0';
        }
      });
      sessionStorage.setItem('drugSelected', JSON.stringify(this.drugSelected));
    },
    drugsShow() {
      this.show = true;
      let Drugs = [];
      this.tableData.forEach((element) => {
        if (element.number !== '0' && element.number) {
          Drugs.push(element);
        }
      });

      this.drugsList = Drugs;
    },
    onClickLeft() {
      this.barCode_show = false;
      let Drugs = [];
      let Drugs_name = [];
      this.drugSelected.forEach((element) => {
        if (element.number !== '0' && element.number) {
          element.imgurl = '';
          Drugs.push(element);
          Drugs_name.push(`${element.name}x ${element.number}`);
        }
      });
      if (Drugs_name.length !== 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
            h('span', null, '您当前选择药品为'),
            h('i', { style: 'font-weight: 800' }, Drugs_name.join('， ')),
          ]),
          showCancelButton: true,
          confirmButtonText: '选择完毕',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            this.onClickButton();
            this.$router.back(-1);
            done();
          },
        });
      } else {
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
            h('span', null, '您当前暂未选择药品'),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          beforeClose: (action, instance, done) => {
            if (action == 'confirm') {
              this.onClickButton();
              done();
              this.$router.back(-1);
              // this.$router.back(-1)
            } else {
              done();
            }
          },
        });
      }
    },
    onClickButton() {
      let Drugs = [];
      this.drugSelected.forEach((element) => {
        if (element.number !== '0' && element.number) {
          element.imgurl = '';
          Drugs.push(element);
        }
      });
      if (this.$route.query.type == 'current') {
        sessionStorage.setItem('Current', JSON.stringify(Drugs));
      } else {
        sessionStorage.setItem('Drugs', JSON.stringify(Drugs));
        sessionStorage.setItem(
          'drugSelected',
          JSON.stringify(this.drugSelected)
        );
      }
    },
    DrugsCli(item) {
      this.search_value = item.name;
      this.loadList();
    },
    barCode(Code) {
      this.search_value = Code;
      this.loadList();
    },
    async loadList() {
      if (this.search_value !== '' && this.search_value.length < 1) {
        return;
      }
      this.count = 0
      try {
        let drugsRes = null;
        if (this.search_value.length == 13) {
          let data = {
            pharmacy_id: this.return_drugs_id(),
            barCode:this.search_value
          }
          drugsRes = await this.$api.article.whoosh('drugs', data);
        } else {
          let data = {
            pharmacy_id: this.return_drugs_id(),
          };
          if (this.search_value !== '') {
            data['summary'] = `${this.search_value}`;
          }
          drugsRes = await this.$api.article.whoosh('drugs', data);
        }
        const drugsPromises = drugsRes.data.result.map(async (element) => {
          element['number'] = 0;
          if (element.maxNumber == 'None') {
            element.maxNumber = 10;
          }

          if (sessionStorage.getItem('drugSelected')) {
            this.drugSelected = this.removeZeroValues(
              JSON.parse(sessionStorage.getItem('drugSelected'))
            );
            this.drugSelected.forEach((item) => {
              if (element.id == item.id) {
                element.number = item.number;
              }
            });
          }
          let PublicDrugsParams = {
            search_db: 'PublicDrugs',
            query_type: 'equal',
            no_count: '1',
          };

          let PublicDrugsData = {
            query: [
              {
                fild: { id: element.publicdrugs_id },
                type: '=',
                condition: '&&',
              },
            ],
          };
          if (this.search_value.length == 13) {
            const publicDrugsRes = await this.$api.article.query2(
              PublicDrugsParams,
              PublicDrugsData
            );
            element['PublicDrugs'] = publicDrugsRes.data.result;
          } else {
            let PublicDrugs = JSON.parse(JSON.stringify(element));
            PublicDrugs.id = element.publicdrugs_id;
            element['PublicDrugs'] = [PublicDrugs];
            if (element.PublicDrugs[0].img_url) {
              element[
                'imgurl'
              ] = `${base.weburl}static/img/${element.PublicDrugs[0].img_url}`;
            } else {
              element['imgurl'] = '';
            }
            return Promise.resolve();
          }
        });
        await Promise.all(drugsPromises);
        this.tableData = drugsRes.data.result;
        this.new_tableData = drugsRes.data.result.slice(0, 10);
        this.historyTableData = JSON.parse(JSON.stringify(this.tableData));
        let that = this;
        setTimeout(function () {
          that.headershow = false;
        }, 5000);
        if (this.search_value !== '') {
          let specs = ['全部'];
          let manufacturingEnterprises = ['全部'];
          this.tableData.forEach((element) => {
            specs.push(element.PublicDrugs[0].specs);
            manufacturingEnterprises.push(
              element.PublicDrugs[0].manufacturingEnterprise
            );
          });
          let specss = [];
          let manufacturingEnterprise = [];
          Array.from(new Set(specs)).forEach((element) => {
            specss.push({ text: element, value: element });
          });
          Array.from(new Set(manufacturingEnterprises)).forEach((element) => {
            manufacturingEnterprise.push({ text: element, value: element });
          });
          this.specss = specss;
          this.manufacturingEnterprise = manufacturingEnterprise;
        }
        this.backups = JSON.parse(JSON.stringify(drugsRes.data.result));
      } catch (error) {}
    },
    number_vaule() {
      let number = 0;
      this.tableData.forEach((element) => {
        if (element.price !== 'None' && element.number !== 0) {
          number += element.price * element.number;
        }
      });
      return number;
    },
    drugs_details(id) {
      this.onClickButton();
      this.$router.push({
        name: 'drugs_details',
        query: {
          id: id,
        },
      });
    },
    changeSpecs(key) {
      if (this.specsValue == '' && this.manufacturingEnterpriseValue == '') {
        return;
      }

      let tableData = [];
      this.tableData.forEach((element) => {
        let matchSpecs = 
          this.specsValue == '' || 
          this.specsValue == '全部' ||
          element.PublicDrugs[0].specs == this.specsValue;

        let matchManufacturer = 
          this.manufacturingEnterpriseValue == '' ||
          this.manufacturingEnterpriseValue == '全部' ||
          element.PublicDrugs[0].manufacturingEnterprise == this.manufacturingEnterpriseValue;
        if (matchSpecs && matchManufacturer) {
          tableData.push(element);
        }
      });

      this.new_tableData = tableData
    },
    debounce(delay) {
      let that = this;
      let timeoutId; // 定时器ID用于清除定时器
      clearTimeout(timeoutId); // 清除已有的定时器
      // 设置一个新的定时器，在指定的延迟时间后执行原函数
      timeoutId = setTimeout(function () {
        that.loadList();
      }, delay);
    },
  },
  computed: {
    hasParameters(imageUrl) {
      return function (imageUrl) {
        return /img\/(.+)/.test(imageUrl);
      };
    },
  },
  async created() {
    this.state = this.$route.query.type;
    this.disease = JSON.parse(sessionStorage.getItem('disease'));
    this.loadList();
    const prescriptionRes = await this.$api.article.query(
      {
        search_db: 'Pres',
        query_type: 'first',
      },
      {
        query: {
          found_id: localStorage.getItem('userrole_id'),
        },
      }
    );
    if (
      prescriptionRes.data.result.medicine &&
      prescriptionRes.data.result.medicine !== 'None'
    ) {
      let medicine = JSON.parse(prescriptionRes.data.result.medicine);
      let drug_id = medicine.map((element) => element.skuId);
      let LastUsedDrugs = [];
      drug_id.forEach((element) => {
        let params = {
          search_db: 'Drugs',
          query_type: `limit|0-4`,
          no_count: '1',
        };
        let data = {
          query: [
            {
              fild: { publicdrugs_id: element },
              type: '=',
              condition: '&&',
            },
            {
              fild: { pharmacy_id: this.return_drugs_id() },
              type: '=',
              condition: '&&',
            },
          ],
        };
        this.$api.article.query2(params, data).then((res) => {
          res.data.result.forEach((element) => {
            LastUsedDrugs.push(element);
          });
        });
      });
      this.LastUsedDrugs = LastUsedDrugs;
    }
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.btn_number{
  display: flex;
  justify-content: end;
}
.van-nav-bar {
  &__title {
    color: #111 !important;
    font-weight: 600 !important;
  }
}

.bbb {
  background: url('~@/assets/images/page-bg-2.png') no-repeat;
  background-size: contain;
  background-color: #fff;
  height: 100vh !important;
}

.page-wrapper {
  padding: 1.5rem 0.78rem 4.03rem;
  height: calc(100% - 44px);

  .search-wrap {
    display: flex;
    align-items: center;
    height: 2.09rem;

    .left {
      margin-right: 0.66rem;
      padding: 0 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      background: #f2f2f2;
      border-radius: 1.05rem;
      .icon {
        margin-right: 0.5rem;
      }

      .input {
        border-width: 0;
        flex: 1;

        height: 1rem;
        font-size: 0.8rem;
        background: transparent;
      }
    }

    & > .icon {
      width: 1.34rem;
      height: 1.34rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .list {
    padding: 0 1.17rem;
    height: calc(100% - 2.09rem);
    display: flex;
    flex-direction: column;

    overflow-y: auto;

    .item {
      padding: 0.81rem 0 0.47rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-top: 0.06rem solid #e9ebed;

      &.un-line {
        border-top-width: 0;
      }

      .img {
        margin-right: 1.19rem;
        width: 6.47rem;
        height: 6.69rem;
        border-radius: 0.7rem;
      }

      .info {
        flex: 1;
        .title {
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1rem;
          color: #171725;
          line-height: 1.5rem;
        }

        .spaces {
          font-size: 0.94rem;
          color: #1b1b1b;
          line-height: 1.38rem;
        }

        p {
          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 0.63rem;
          color: #78828a;
          line-height: 1.2rem;
        }
      }

      .operate {
        margin-top: 0.6rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .inputNumber {
          margin-right: 1rem;
          display: flex;
          align-items: center;
        }

        .input {
          margin: 0 0.28rem;
          border-width: 0;
          width: 2rem;
          line-height: 1.53rem;
          text-align: center;

          border-bottom: 0.06rem solid #868686;
        }

        .add,
        .minus {
          position: relative;
          width: 1.53rem;
          height: 1.53rem;
          background: #1296db;

          border-radius: 50%;

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0.85rem;
            height: 0.2rem;

            border-radius: 0.2rem;

            background-color: #fff;
          }
        }

        .add {
          &::before {
            height: 0.85rem;
            width: 0.2rem;
          }
        }
        .minus {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .footer-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-right: 1.16rem;
    width: 100%;
    height: 4.03rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.54rem 0rem rgba(153, 153, 153, 0.15);

    display: flex;
    align-items: center;
    justify-content: flex-end;

    z-index: 99;

    .num-wrap {
      position: absolute;
      top: -1.25rem;
      left: 1.5rem;
      width: 3.66rem;
      height: 3.66rem;

      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
      }

      .num {
        position: absolute;
        top: 0;
        right: -0.62rem;
        padding: 0 0.41rem;
        min-width: 1.25rem;
        height: 1.25rem;
        background: #cd0101;
        border-radius: 1.25rem;

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: SourceHanSansCN;
        font-weight: 500;
        font-size: 0.94rem;
        color: #ffffff;
      }
    }

    .button {
      width: 6.28rem;
      height: 2.41rem;
      background: #409eff;
      border-radius: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.94rem;
      color: #ffffff;
    }
  }
}
</style>

import axios from '@/api/index'
import module_axios from '@/module/api/index'
import { Message  } from 'element-ui';
import router from '../router/index.ts';
import mixin from './mixin'
import { Loading } from 'element-ui'
let loading;


function showLoading() {
  loading = Loading.service({
      lock: true,
      text: '登陆中...',
      background: 'rgba(0, 0, 0, 0.7)',
  })
}
function hideLoading() {
  loading.close();
}
const module_function = {   
    password_login(UserName,PassWord,url,role){
        let data={
            'auth':{'username': UserName, 'password': PassWord}
        }
        axios.article.login(data).then(res=>{
            if(res.data.result.token){
                    localStorage.setItem('userinfo', JSON.stringify(res.data.result))
                    module_axios.article.getrole('user').then(res=>{
                        if(res.data.result.length == 0){
                            router.push('/UserInfo')
                            return 
                        }
                        if(role){  //是否开启权限管理
                            let result = res.data.result.find(item=>{
                                return item.role_model == 'patient'
                            })
                            if(result){
                                localStorage.setItem("userrole_id",result.userrole_id);
                                localStorage.setItem("user",JSON.stringify(result));
                                module_axios.article.userpower(result.role_id).then(res=>{
                                    localStorage.setItem("userpower",JSON.stringify(res.data.result));
                                    localStorage.setItem("login_role",role);
                                    router.push(url)
                                })
                            }else{
                                Message({
                                    showClose: true,
                                    message: '账号密码错误',
                                    type: 'error'
                                });
                            }
                            
                        }else{
                            localStorage.setItem("userrole_id",res.data.result[0].userrole_id);
                            localStorage.setItem("user",JSON.stringify(res.data.result[0]));
                            router.push(url)
                        }
                        
                })
            }else{
                Message({
                    showClose: true,
                    message: '账号密码错误',
                    type: 'error'
                });
            }
        })
   },
   async sms_login(UserName, code, url, usercreated) {
    sessionStorage.setItem('trylogin',true)
    showLoading()
    try {
      let data = {
        'auth': { 'username': UserName, 'code': code }
      };

      const res = await axios.article.smstoken(data);
      
      hideLoading()
      if (res.data.result.token) {
        sessionStorage.removeItem('wechat_login_tryed')
        localStorage.setItem('userinfo', JSON.stringify(res.data.result));
        const getuserinfo = await module_axios.article.getrole('userinfo');
        if(getuserinfo.data.result.length !==0){
          this.login_put_userrole(url)
        }else{
          const userRes = await module_axios.article.usercreated({
            user: {
              name: '',
              sex: '',
              age: '',
              identity: '',
              address: ''
            }
          });
          if(userRes.data.result.id){
            this.login_put_userrole(url)
          }else{
            Message({
              showClose: true,
              message: '登陆失败请重试',
              type: 'error'
            });
          }
        }
      } else {
        Message({
          showClose: true,
          message: '账号密码错误',
          type: 'error'
        });
      }
    } catch (error) {
      // 处理错误
      console.error(error);
    }

    // 函数完成后执行的代码
    // ...
   },
   //获取互联网医院 并且 同步互联网医院
   getihname(url){
    module_axios.article.getihname().then(res=>{
      localStorage.setItem('ih_type',res.data.result.name)
      
      router.push(url);
      // module_axios.article.postihuserinfo(res.data.result.name).then(res=>{
      //   router.push(url);
      // })
    })
   },
   async login_put_userrole(url){
    const userRoleRes =  await module_axios.article.getrole('user');
    let patientrole = userRoleRes.data.result.filter(element => element.role_model == 'patient')
    if(patientrole.length ==0){
        const roleRes = await module_axios.article.getrole('role');
        const role = roleRes.data.result.find(element => element.role_model === 'patient');
        const userRoleRes = await module_axios.article.userrole({
          role: {
            role_id: role.id
          }
        });
        if(userRoleRes.data.result.id){
          const userRoleRes =  await module_axios.article.getrole('user');
          let patientrole = userRoleRes.data.result.filter(element => element.role_model == 'patient')
          if(patientrole.length !==0){
            localStorage.setItem('userrole_id', patientrole[0].userrole_id);
            localStorage.setItem('user', JSON.stringify(patientrole[0]));
            this.getihname(url)
          }
        }else{
          Message({
            showClose: true,
            message: '登陆失败请重试',
            type: 'error'
          });
        }
    }else{
      localStorage.setItem('userrole_id', patientrole[0].userrole_id);
      localStorage.setItem('user', JSON.stringify(userRoleRes.data.result[0]));
      this.getihname(url)
    }
   }
    
}


export default module_function;